import React from 'react';

export default function Header({ setFilter }) {
  return (
    <header>
        <div>
            <span className='logo'>ТАРИФЫ</span>
            <ul className='nav'>
                <li onClick={() => setFilter('all')}>Все</li>
                <li onClick={() => setFilter('internet')}>Интернет</li>
                <li onClick={() => setFilter('mobile')}>Мобильная связь</li>
                <li onClick={() => setFilter('tv')}>Телевиденье</li>
            </ul>
        </div>
        <div className='presentation'></div>
    </header>
  );
}
